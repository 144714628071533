import { Create, SimpleForm } from 'react-admin'
import Fields from './Fields'
import { omit } from 'lodash'
import { InvitationData } from 'feathers-api'

const InvitationCreate = () => {
  return (
    <Create redirect="list" transform={
      (data:InvitationData) => omit(data, 'businessUnit')
    }>
      <SimpleForm>
        <Fields />
      </SimpleForm>
    </Create>
  )
}

export default InvitationCreate
