import React from 'react'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import { useNavigate } from 'react-router-dom'
import app from '../../feathersClient'
import { useTranslate } from 'react-admin'

export default () => {
  const navigate = useNavigate()
  const translate = useTranslate()
  const [password, setPassword] = React.useState('')
  const [username, setUsername] = React.useState('')
  const [origin, setOrigin] = React.useState('')
  const [overwrite, setOverwrite] = React.useState(true)
  const [error, setError] = React.useState('')
  const [submitted, setSubmitted] = React.useState(false)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const updatedError = ''

    if (!origin || !password || !username) {
      setError('All fields are required')
      return
    }

    try {
      setSubmitted(true)
      console.log(await app.service('script').create({
        script: 'importTranslations',
        payload: {
          username,
          password,
          origin,
          overwrite
        },
        awaitRun: true
      }))
      console.log('import done')
    } catch (e: any) {
      setError(translate(`${e}`))
      return
    }
    setSubmitted(false)

    navigate('/translation')
  }
  
  return <div>
    <h2>Import Translations</h2>
    {!!error && <p style={{ margin: '16px 0'}}>{error}</p>}
    <Box component='form' onSubmit={handleSubmit} sx={{ p: 3 }}>
      <CssBaseline />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name='origin'
            label='Remote HTTP Origin'
            id='origin'
            value={origin}
            onChange={e => setOrigin(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name='username'
            label='Email or Phone Number'
            id='username'
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Checkbox
            required
            name='overwrite'
            id='overwrite'
            checked={overwrite}
            onChange={() => setOverwrite(!overwrite)}
          />
          <label>Overwrite Newer Records</label>
        </Grid>
      </Grid>
      <Button
        type='submit'
        disabled={submitted}
        fullWidth
        variant='contained'
        sx={{ mt: 3, mb: 2 }}
      >
        Import
      </Button>
    </Box>
  </div>
}
