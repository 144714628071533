import { useContext, useEffect, useState } from 'react'
import ChartContext from '../ChartContext'
import {
  AuthProvider,
  ResourceContext,
  RecordContext
} from 'react-admin'
import { noop, startCase, uniqueId } from 'lodash'
import ArrowUpIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownIcon from '@mui/icons-material/ArrowDownward'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'
import { ClientApplication, ResultsAggregation } from 'feathers-api'

export type ResultsTableProps = Omit<ResultsAggregation, 'service' | 'limit' | 'skip' | 'sortDir' | 'sortField' | 'where'> & {
  id: string
  children: JSX.Element[]
  authProvider?: AuthProvider
  lottery_ids?: number[]
  service: Parameters<ClientApplication['service']>[0]
  where?: any
  recordId?: string | number
}

export type HeaderCellProps = {
  prop: string
  cell: JSX.Element
  sortField: string
  sortDir: string
  setSortField: Function
  setSortDir: Function
  setSkip: Function
}

const sortIconStyle = { fontSize: 16, display: 'inline-block', verticalAlign: 'middle', marginLeft: 1 }

const HeaderCell = ({ prop, cell, sortField = 'id', sortDir = 'asc', setSortField = noop, setSortDir = noop, setSkip = noop }: HeaderCellProps) => {
  const sortable = cell.props.sortable !== false
  const isCurrentSortColumn = prop === sortField
  const label = cell.props.label
  
  const onClick = sortable ? () => {
    if (isCurrentSortColumn) {
      setSortDir(sortDir === 'asc' ? 'desc' : 'asc')
      setSkip(0)
    } else {
      setSortField(prop)
      setSortDir('asc')
      setSkip(0)
    }
  } : noop

  return <TableCell style={sortable ? { cursor: 'pointer', minWidth: 120 } : { minWidth: 120 }} onClick={onClick}>
    <span>{label || startCase(prop)}</span>
    {isCurrentSortColumn ? <>
      {sortDir === 'asc' ? <ArrowUpIcon sx={sortIconStyle} /> : <ArrowDownIcon sx={sortIconStyle} />}
    </> : <></>}
  </TableCell>
}

const ResultsTable = ({ children, id, service, where, aggregationId, lottery_ids, recordId }: ResultsTableProps) => {
  const { search } = useContext(ChartContext)
  const { registerAggregation, onResults, savedValues } = search
  const [{ results: { data: rows, total } }, setSearchNodes] = useState<any>({ results: { data: [], total: 0 }, settings: {}, aggregation: {}, filters: {} })

  const [skip, setSkip] = useState(Number((savedValues?.aggregations[id] as ResultsAggregation)?.skip || (search.aggregations[id] as ResultsAggregation)?.skip || 0))
  const [limit, setLimit] = useState(Number((savedValues?.aggregations[id] as ResultsAggregation)?.limit || (search.aggregations[id] as ResultsAggregation)?.limit || 10))
  const [sortField, setSortField] = useState((savedValues?.aggregations[id] as ResultsAggregation)?.sortField || (search.aggregations[id] as ResultsAggregation)?.sortField || 'id')
  const [sortDir, setSortDir] = useState((savedValues?.aggregations[id] as ResultsAggregation)?.sortDir || (search.aggregations[id] as ResultsAggregation)?.sortDir || 'asc')

  onResults(id, (nodes: any) => setSearchNodes(nodes))

  useEffect(() => {
    registerAggregation({
      id,
      aggregationId: aggregationId as any,
      service,
      where,
      skip,
      limit,
      sortField,
      sortDir,
      ...(lottery_ids ? { lottery_ids } : {}),
      ...(recordId ? { recordId } : {})
    } as any)
  }, [limit, skip, sortField, sortDir])

  const columns = children.map(child => child.props.source)

  return !!rows[0] ? <>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map(prop => <HeaderCell key={prop || uniqueId()} prop={prop} cell={children.find(child => child.props.source === prop)!} {...{ sortField, setSortField, sortDir, setSortDir, setSkip }} />)}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any) => (
            <ResourceContext.Provider key={row.id} value={service}>
              <RecordContext.Provider value={row}>
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {children.map(child => <TableCell key={child.props.source || uniqueId()}>{child}</TableCell>)}
                </TableRow>
              </RecordContext.Provider>
            </ResourceContext.Provider>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      component="div"
      count={total}
      page={skip / limit}
      rowsPerPage={limit}
      onRowsPerPageChange={(e: any) => {
        setLimit(e.target.value)
        setSkip(0)
      }}
      onPageChange={(e: any, newPage: number) => setSkip((newPage * limit))}
    />
  </> : <></>
}

export default ResultsTable
