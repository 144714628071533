import React from 'react'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { useState } from 'react'
import Copyright from './components/Copyright'
import { useNavigate } from 'react-router-dom'
import { useRefresh, useTranslate } from 'react-admin'
import { restClient } from './feathersClient'

interface Errors {
  code?: string
}

export default () => {
  const [code, setCode] = useState('')
  const [email, setEmail] = useState(sessionStorage.getItem('email') || '')
  const [phone, setPhone] = useState(sessionStorage.getItem('phone') || '')
  const navigate = useNavigate()
  const [errors, setErrors] = React.useState<Errors>({})
  const translate = useTranslate()
  const refresh = useRefresh()

  const isEmail = sessionStorage.getItem('email')

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    
    try {
      await restClient.service('verification').patch(email || phone, { code })
    } catch (e) {
      if (!String(e).startsWith('NotFound')) {
        setErrors({ code: translate(`${e}`)})
      }
    }
    if (!errors?.code) {
      sessionStorage.removeItem('email')
      sessionStorage.removeItem('phone')
      sessionStorage.setItem('verificationSuccess', 'yes')
      refresh()
      navigate('/login?successful-verification')
    }
  }

  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ width: 160, height: 160 }}>
            <img style={{ width: '100%' }} src="/nclusion-ring-logo.png" />
          </div>
          <Typography component="h1" variant="h5">
            {translate('nclusion.verify_account')}
          </Typography>
          <Box component="div" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Typography component="p" sx={{ textAlign: 'center' }}>
            {translate('nclusion.verify_account_text')}
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="username"
                    name="username"
                    required
                    fullWidth
                    id="username"
                    label={isEmail ? translate('nclusion.email') : translate('nclusion.phone')}
                    value={isEmail ? email : phone}
                    onChange={e => isEmail ? setEmail(e.target.value) : setPhone(e.target.value)}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="code"
                    required
                    fullWidth
                    id="code"
                    label={translate('nclusion.code')}
                    value={code}
                    onChange={e => setCode(e.target.value)}
                    autoFocus
                    error={!!errors.code}
                    {...(errors.code ? { helperText: errors.code } : {})}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {translate('nclusion.verify')}
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="#/login?resend-confirmation" variant="body2" sx={{ mx: 4}}>
                    {translate('nclusion.no_code')}
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#/login" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
  )
}
