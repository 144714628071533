import { Fragment, useContext, useEffect, useState } from 'react'
import ChartContext from '../ChartContext'
import authProvider from '../../../authProvider'
import {
  DataProviderContext,
  AuthContext,
  useRecordContext,
  ShowBase
} from 'react-admin'
import { ClientApplication, JoinedRecordAggregation } from 'feathers-api'

export type JoinedRecordProps = Omit<JoinedRecordAggregation, 'recordId' | 'service'> & {
  children: JSX.Element 
  service: string
}

export default ({ id, aggregationId, children, service }: JoinedRecordProps) => {
  const { search } = useContext(ChartContext)
  const { registerAggregation, onResults } = search
  const [key, setKey] = useState(true)
  onResults(id, () => setKey(!key))

  const { id: recordId } = useRecordContext() || {}

  useEffect(() => {
    if (recordId) {
      registerAggregation({
        id,
        aggregationId,
        service: service as any,
        recordId: typeof recordId === 'string' && recordId.includes('-') ? recordId : Number(recordId)
      })
    }
  }, [recordId])

  return <AuthContext.Provider value={authProvider}>
    <DataProviderContext.Provider value={search}>
      <Fragment key={`${key}`}>
        <ShowBase queryOptions={{ meta: { id, recordId, ...(typeof recordId === 'string' && recordId.includes('-') ? { swapId: 'uuid' } : {}) } }}>
          {children}
        </ShowBase>
      </Fragment>
    </DataProviderContext.Provider>
  </AuthContext.Provider>
}
