import { useEffect, useState } from 'react'
import ChartContext from './ChartContext'
import Search from './Search'
import { ClientApplication } from 'feathers-api'

export default ({ children, storageKey, listen, currency }: { children: JSX.Element | JSX.Element[], storageKey?: string, listen?: (Parameters<ClientApplication['service']>[0])[], currency?: string }) => {
  const [search] = useState<any>(new Search({ storageKey, listen, currency }))

  return search && <ChartContext.Provider value={{ search }}>
    {children}
  </ChartContext.Provider>
}