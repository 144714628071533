import { Edit, SimpleForm } from 'react-admin'
import Fields from './Fields'
import { InvitationPatch } from 'feathers-api'
import { omit } from 'lodash'

const InvitationEdit = () => (
  <Edit transform={
    (data:InvitationPatch) => omit(data, 'businessUnit')
  }>
    <SimpleForm>
      <Fields />
    </SimpleForm>
  </Edit>
)

export default InvitationEdit
