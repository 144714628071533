import {
  ChipField,
  DateField,
  FunctionField,
  Link,
  NumberField,
  ReferenceManyField,
  SingleFieldList,
  ShowBase,
  TabbedShowLayout,
  TextField,
  useRecordContext,
  useTranslate,
  WrapperField,
  Button,
} from 'react-admin'
import {
  Card,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material'
import ChartController from '../../components/charts/ChartController'
import JoinedRecord from '../../components/charts/aggregations/JoinedRecord'
import { startCase } from 'lodash'
import { useNavigate } from 'react-router-dom'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import ResultsTable from '../../components/charts/aggregations/ResultsTable'
import { formatWinningNumbersString, getLocale } from '../../util'

const Wagers = () => {
  const { uuid } = useRecordContext()
  const locale = getLocale()

  return (
    <ResultsTable
      id='wagers'
      service='wager'
      aggregationId='results'
      where={['ticket_uuid', uuid]}
    >
      <TextField source='pick' />
      <TextField source='bet_amount' />
      <WrapperField source='drawing'>
        <DrawingField />
      </WrapperField>
      <WrapperField source='game'>
        <GameField />
      </WrapperField>
      <NumberField source='payout_amount' locales={locale} />
      <TextField source='promotion' />
      <TextField source='promotion_amount' />
      <TextField source='status' />
      <DateField showTime source='createdAt' />
      <DateField showTime source='updatedAt' />
    </ResultsTable>
  )
}

const DrawingField = () => {
  const record = useRecordContext()

  return (
    !!record && (
      <Link
        to={`/drawing/${
          record?.drawing_id || record?.session?.game_drawing?.drawing_id
        }/show`}
      >
        {record?.drawing_name || record?.session?.game_drawing?.drawing?.name}
      </Link>
    )
  )
}

const GameField = () => {
  const record = useRecordContext()

  return (
    !!record && (
      <Link
        to={`/game/${
          record?.game_id || record?.session?.game_drawing?.game_id
        }/show`}
      >
        {record?.game_name || record?.session?.game_drawing?.game?.name}
      </Link>
    )
  )
}

const AssociateField = () => {
  const record = useRecordContext()
  return record?.associate_id ? (
    <Link to={`/associate/${record?.associate_id}/show`}>
      {record?.associate_user_first_name} {record?.associate_user_last_name}
    </Link>
  ) : null
}

const BankField = () => {
  const record = useRecordContext()
  return record?.bank_id ? (
    <Link to={`/bank/${record?.bank_id}/show`}>{record?.bank_name}</Link>
  ) : null
}

const SupervisorField = ({relationship}:{relationship:any}) => {
  return relationship?.parent_associate_id ? (
    <Link to={`/associate/${relationship?.parent_associate_id}/show`}>
      {relationship?.parent_associate_user_first_name} {relationship?.parent_associate_user_last_name}
    </Link>
  ) : null
}

const RelationshipFields = () => {
  const record = useRecordContext()
  const translate = useTranslate()

  return record?.relationships?.map((relationship:any) =>
    <ListItem sx={{ justifyContent: 'space-between' }} key={`relationship-${relationship.relationship_tree_id}`}>
      <Typography>
        {translate(relationship.relationship_name || '')}
      </Typography>
      <SupervisorField relationship={relationship}/>
    </ListItem>
  ) || null
}

export default () => {
  const translate = useTranslate()
  const navigate = useNavigate()
  const locale = getLocale()

  const uuid = window.location.hash.split('/')[2]

  return (
    <ShowBase resource='ticket'>
      <ChartController>
        <Container sx={{ margin: 5 }}>
          <h3 style={{ marginTop: 0 }}>
            {translate('nclusion.ticket')} {uuid}
          </h3>
          <Button
            variant='outlined'
            onClick={() => navigate('/ticket-denormalized')}
            startIcon={<ArrowBackOutlinedIcon />}
            sx={{ marginBottom: 3 }}
            label={translate('nclusion.backToTickets')}
          />
          <TabbedShowLayout>
            <TabbedShowLayout.Tab label={translate('nclusion.info')}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Card
                    sx={{
                      padding: 3,
                      height: '100%',
                      flexDirection: 'column',
                      display: 'flex',
                    }}
                  >
                    <List>
                      <ListItem sx={{ justifyContent: 'space-between' }}>
                        <Typography>{translate('nclusion.status')}</Typography>
                        <FunctionField
                          render={(record: Record<string, any>) => (
                            <Typography sx={{ fontWeight: 'bold' }}>
                              {startCase(record.status)}
                            </Typography>
                          )}
                        />
                      </ListItem>
                      <ListItem sx={{ justifyContent: 'space-between' }}>
                        <Typography>
                          {translate('nclusion.transaction')}
                        </Typography>
                        <ReferenceManyField
                          reference='transaction'
                          target='order_id'
                          source='uuid'
                        >
                          <SingleFieldList
                            linkType='show'
                            sx={{ justifyContent: 'end' }}
                          >
                            <ChipField source='name' />
                          </SingleFieldList>
                        </ReferenceManyField>
                      </ListItem>
                    </List>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    sx={{
                      padding: 3,
                      height: '100%',
                      flexDirection: 'column',
                      display: 'flex',
                    }}
                  >
                    <JoinedRecord
                      id='ticketInfo'
                      service='ticket'
                      aggregationId='ticketInfo'
                    >
                      <List>
                        <ListItem sx={{ justifyContent: 'space-between' }}>
                          <Typography>
                            {translate('nclusion.associate')}
                          </Typography>
                          <AssociateField />
                        </ListItem>
                        <ListItem sx={{ justifyContent: 'space-between' }}>
                          <Typography>{translate('nclusion.bank')}</Typography>
                          <BankField />
                        </ListItem>
                        <RelationshipFields />
                        <ListItem sx={{ justifyContent: 'space-between' }}>
                          <Typography>
                            {translate('nclusion.activeTime')}
                          </Typography>
                          <DateField
                            sx={{ fontWeight: 'bold' }}
                            source='active_time'
                            showTime
                          />
                        </ListItem>
                      </List>
                    </JoinedRecord>
                  </Card>
                </Grid>
                <Grid item xs={4}>
                  <Card
                    sx={{
                      padding: 3,
                      height: '100%',
                      flexDirection: 'column',
                      display: 'flex',
                    }}
                  >
                    <List>
                      <ListItem sx={{ justifyContent: 'space-between' }}>
                        <Typography>
                          {translate('nclusion.timeCreated')}
                        </Typography>
                        <DateField
                          sx={{ fontWeight: 'bold' }}
                          source='createdAt'
                          showTime
                        />
                      </ListItem>
                      <ListItem sx={{ justifyContent: 'space-between' }}>
                        <Typography>
                          {translate('nclusion.lastUpdated')}
                        </Typography>
                        <DateField
                          sx={{ fontWeight: 'bold' }}
                          source='updatedAt'
                          showTime
                        />
                      </ListItem>
                      <ListItem sx={{ justifyContent: 'space-between' }}>
                        <Typography>
                          {translate('nclusion.totalPaid')}
                        </Typography>
                        <NumberField
                          sx={{ fontWeight: 'bold' }}
                          source='total_paid'
                          options={{
                            style: 'currency',
                            currency: 'HTG',
                            minimumFractionDigits: 0,
                          }}
                          locales={locale}
                        />
                      </ListItem>
                    </List>
                  </Card>
                </Grid>
              </Grid>
              <Typography variant='h5' sx={{ marginTop: 3, marginBottom: 3 }}>
                {translate('nclusion.winningNumbers')}
              </Typography>
              <ResultsTable
                id='winningNumbers'
                service='ticket'
                aggregationId='ticketDrawingNumbers'
                recordId={uuid}
              >
                <TextField source='session_id' />
                <WrapperField source='drawing'>
                  <DrawingField />
                </WrapperField>
                <WrapperField source='game'>
                  <GameField />
                </WrapperField>
                <FunctionField
                  source='winning_numbers'
                  render={(record: { winning_numbers: string }) =>
                    formatWinningNumbersString(record.winning_numbers)
                  }
                />
                <TextField source='pick' />
                <TextField source='bet_amount' />
                <NumberField source='payout_amount' locales={locale} />
              </ResultsTable>
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label={translate('nclusion.wagers')}>
              <Wagers />
            </TabbedShowLayout.Tab>
          </TabbedShowLayout>
        </Container>
      </ChartController>
    </ShowBase>
  )
}
