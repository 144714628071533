import { useTheme, useTranslate } from "react-admin"
import ChartController from "./charts/ChartController"
import TemporalLineChart from "./charts/aggregations/TemporalLineChart"
import BarChart from "./charts/aggregations/BarChart"
import { useNavigate } from 'react-router-dom'
import { camelCase, fromPairs, last, sortBy, sumBy } from "lodash"
import SimpleAggregate from "./charts/aggregations/SimpleAggregate"
import DateRangePicker from "./charts/filters/DateRangePicker"

const bankStatusArray = [
  'acquired',
  'beingPainted',
  'buildingCounter',
  'active',
  'suspended',
  'ceasedLease',
  'closed',
  'archived'
]

const BankDashboard = () => {
  const translate = useTranslate()
  const navigate = useNavigate()
  const [theme] = useTheme()
  
  return <div style={{ marginTop: 10 }}>
      <ChartController listen={['transaction']}>
        <>
        <div style={{ marginBottom: 20 }}>
          <SimpleAggregate
            id="assignedBankCount"
            service="lottery"
            aggregationId="lotteryAssignedBanks"
            valueField="bank_id"
            aggs={['uniq']}
            labels={{
              uniq: translate('nclusion.assignedBanks')
            }}
          />
        </div>
        <div style={{ marginBottom: 20 }}>
          <BarChart
            id="bankStatus"
            aggregationId="simpleGroup"
            service="bank"
            keyField="status"
            valueField="id"
            agg="count"
            title={translate('nclusion.locationStatus')}
            transform={(data: any) => sortBy(data, (p: any) => bankStatusArray.indexOf(p.status)).map((d: any) => ({ ...d, status: translate(`nclusion.bankStatus.${d.status}`) }))}
            config={{
              margin: { bottom: 60, top: 40, left: 40, right: 40 },
              onClick: ({ data: { status } }: { data: { status: string} }) => navigate(`/bank?displayedFilters=%7B"status"%3Atrue%7D&filter=%7B"status"%3A"${camelCase(last(status.split('.')))}"%7D`),
              tooltip: (datum: any) => <div style={{
                backgroundColor: theme === 'dark' ? '#434343' : '#f4f4f4',
                border: `1px solid ${theme === 'dark' ? '#888' : '#bbb'}`,
                boxShadow: `1px 1px 3px ${theme === 'dark' ? '#121212' : '#e1e1e1'}`,
                padding: 4
              }}>
                {`${datum.data.status}: ${Math.abs(datum.data[datum.id])}`}
              </div>
            }}
          />
        </div>
        <div>
          <BarChart
            id="banksOpen"
            aggregationId="openBanks"
            service="transaction"
            keyField="id"
            valueField="value"
            transform={(data: any) => [{
              id: '',
              ...fromPairs(data.map((d: any) => [d.id, d.id === 'inactive' ? Number(0 - d.value) : Number(d.value)])) }
            ]}
            height={240}
            title={translate('nclusion.operationalStatus')}
            config={(results: any) => ({
              layout: "horizontal",
              keys: ['open', 'inactive'],
              valueFormat: Math.abs,
              axisBottom: {
                format: Math.abs
              },
              margin: { bottom: 60, top: 40, left: 40, right: 40 },
              minValue: 0 - sumBy(results, (r: any) => Number(r.value)),
              maxValue: sumBy(results, (r: any) => Number(r.value)),
              tooltip: (datum: any) => <div style={{
                backgroundColor: theme === 'dark' ? '#434343' : '#f4f4f4',
                border: `1px solid ${theme === 'dark' ? '#888' : '#bbb'}`,
                boxShadow: `1px 1px 3px ${theme === 'dark' ? '#121212' : '#e1e1e1'}`,
                padding: 4
              }}>
                {`${translate(`nclusion.operationalStatus.${datum.id}`)}: ${Math.abs(datum.data[datum.id])}`}
              </div>
            })}
          />
        </div>
      </>
      </ChartController>
      <ChartController storageKey="operationalByDay" listen={['ticket-denormalized' as any]}>
        <div style={{ marginTop: 20 }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
            <DateRangePicker id={'createdAt'} field={['createdAt']} />
          </div>
          <TemporalLineChart
            title={translate(`nclusion.operationalBanksByDay`)}
            id='operationalStatus'
            service='transaction'
            aggregationId='operationalBanksHistogram'
            period='day'
            keyField='createdAt'
            valueField='bank_id'
            agg='count'
            periods={['day']}
            fixedPeriod={true}
            config={{
              useMesh: true,
              enableCrosshair: true
            }}
          />
        </div>
      </ChartController>
    </div>
}

export default BankDashboard
