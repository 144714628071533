import { useEffect } from 'react'
import { omit } from 'lodash'
import { List as RAList, useListController, useRefresh } from 'react-admin'
import app from '../feathersClient'

const formatFilterKeys = (key: string) =>
  key.replace('_lt', '').replace('_gte', '')

export const NclusionList = ({ ...componentProps }) => {
  const { filterValues, displayedFilters, setFilters } = useListController()
  const refresh = useRefresh()

  useEffect(() => {
    for (let key in filterValues) {
      if (!displayedFilters[formatFilterKeys(key)]) {
        setFilters(omit(filterValues, key), displayedFilters)
      }
    }
  }, [filterValues, displayedFilters])

  useEffect(() => {
    if (componentProps.listen?.length) {
      for (const service of componentProps.listen) {
        app.service(service).on('created', refresh)
        app.service(service).on('patched', refresh)
        app.service(service).on('deleted', refresh)
      }

      return () => {
        for (const service of componentProps.listen) {
          app.service(service).removeListener('created', refresh)
          app.service(service).removeListener('patched', refresh)
          app.service(service).removeListener('deleted', refresh)
        }
      }
    }
  })

  return (
    <RAList {...omit(componentProps, ['children', 'listen'])}>
      {componentProps.children}
    </RAList>
  )
}
