import React from 'react'
import { AppBar, TitlePortal } from 'react-admin'
import Box from '@mui/material/Box';
import UserMenu from './UserMenu'

export default () => <AppBar
  userMenu={<UserMenu />}
>
  <Box sx={{ width: 120, marginTop: 1, marginRight: 3, '> img': { width: '100%' } }}>
    <img src="/nclusion-logo.png" />
  </Box>
  <TitlePortal />
</AppBar>