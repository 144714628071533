import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { values } from 'lodash'
import { useNavigate } from 'react-router-dom'
import Copyright from './components/Copyright'
import { useTranslate, useNotify } from 'react-admin'
import { restClient } from './feathersClient'
import PasswordRequirements from './components/PasswordRequirements'

// when we have a proper user type we'll be able to make this
// uding Partial and keyOf
interface Errors {
  firstName?: string
  lastName?: string
  password?: string
  phone?: string
  email?: string
  confirmPassword?:string
}

type ErrorKey = keyof Errors

const hasLetter = (str: string) => /[a-zA-z]/.test(str)
const hasNumber = (str: string) => /\d/.test(str)
const hasSpecial = (str: string) => /[\!\@\#\$%\^&\*\(\)]/.test(str)

// one symbol, one digit, six long
export const pwTest = (str: string) => {
  return hasLetter(str) && hasNumber(str) && hasSpecial(str)
}

// https://colinhacks.com/essays/reasonable-email-regex - not bad. if we want to pass more we can change this up
const emailRegex = /^([A-Z0-9_+-]+\.?)*[A-Z0-9_+-]@([A-Z0-9][A-Z0-9-]*\.)+[A-Z]{2,}$/i

const isValidPhone = (phone: string) => phone.charAt(0) === '+'.charAt(0)

export default function SignUp() {
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [errors, setErrors] = React.useState<Errors>({})
  const navigate = useNavigate()
  const translate = useTranslate()
  const notify = useNotify()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const updatedErrors: Errors = {}
    const e = (path: ErrorKey, val: string) => {
      updatedErrors[path] = val
    }

    if (password !== confirmPassword) {
      e('confirmPassword', 'Passswords must match')
    }

    if (!pwTest(password)) {
      e('password', 'Password must be at least 6 characters long with at least 1 numeral and one special character')
    }

    const username = email || phone

    if (!username) {
      e('email', 'You must register with at least an email address or a phone number')
    }

    if (email && !emailRegex.test(email)) {
      e('email', 'You must provide a valid email address')
    }

    if (phone && !isValidPhone(phone)) {
      e('phone', 'You must provide a valid phone number beginning with "+" and your country code')
    }
  
    const payload: any = {
      firstName,
      lastName,
      ...(email ? { email } : {}),
      ...(phone ?  { phone } : {}),
      password,
    }

    setErrors(updatedErrors)
    if (values(updatedErrors).length) {
      return
    }

    try {
      sessionStorage.removeItem('email')
      sessionStorage.removeItem('phone')
      await restClient.service('users').create(payload)
      email && sessionStorage.setItem('email', email)
      phone && sessionStorage.setItem('phone', phone)

      navigate('/login?verify')
    } catch (e) {
      notify(translate(`${e}`))
    }
  }

  return (
      <Container key={1} component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ width: 160, height: 160 }}>
            <img style={{ width: '100%' }} src="/nclusion-ring-logo.png" />
          </div>
          <Typography component="h1" variant="h5">
            {translate('nclusion.signup')}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label={translate('nclusion.firstName')}
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label={translate('nclusion.lastName')}
                  name="lastName"
                  autoComplete="family-name"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="email"
                  label={translate('nclusion.email_address')}
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  error={!!errors.email}
                  {...(errors.email ? { helperText: errors.email } : {})}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="phone"
                  label={translate('nclusion.phone_number')}
                  name="phone"
                  autoComplete="phone"
                  value={phone}
                  onChange={e => setPhone(e.target.value.length ? `+${e.target.value.replace(/[^\d]/g, '')}` : '')}
                  error={!!errors.phone}
                  {...(errors.phone ? { helperText: errors.phone } : {})}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label={translate('nclusion.password')}
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  error={!!errors.password}
                  {...(errors.password ? { helperText: errors.password } : {})}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label={translate('nclusion.confirmPassword')}
                  type="password"
                  id="confirmPassword"
                  autoComplete="confirm-new-password"
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                  error={!!errors.confirmPassword}
                  {...(errors.confirmPassword ? { helperText: errors.confirmPassword } : {})}
                />
              </Grid>
            </Grid>
            <div style={{ marginTop: 8 }}>
              <PasswordRequirements />
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {translate('nclusion.signup')}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="#/login" variant="body2">
                  {translate('nclusion.account_already')}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
  );
}
