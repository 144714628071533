import ChartContext from '../ChartContext'
import { useContext, useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { castArray, startCase } from 'lodash'
import { formatCurrency } from '../../../util'
import { ClientApplication, ScalarAggregate, Wheres } from 'feathers-api'

export type SimpleAggregateProps = Omit<ScalarAggregate, 'aggregationId' | 'service'> & {
  height?: number
  labels?: any
  isCurrency?: boolean
  recordId?: number
  lottery_ids?: number[]
  where?: Wheres
  aggregationId?: string
  service: Parameters<ClientApplication['service']>[0]
}

const SimpleAggregateComponent = ({ id, recordId = 0, aggregationId = 'simpleAggregate', service, valueField, aggs, labels = {}, where, lottery_ids, isCurrency }: SimpleAggregateProps) => {
  const { search: { registerAggregation, onResults, currency } } = useContext(ChartContext)
  const translate = useTranslate()
  const [results, setResults] = useState([])

  onResults(id, ({ results }: any) => setResults(results))

  useEffect(() => {
    registerAggregation({
      id,
      service,
      valueField,
      where,
      ...(aggs ? { aggs: castArray(aggs) } : {}),
      aggregationId,
      ...(recordId ? { recordId } : {}),
      ...(lottery_ids ? { lottery_ids } : {})
    } as any)
  }, [recordId])

  return <>{castArray(aggs).map(agg => <div key={`${service}.${valueField}.${agg}`}>
        <div key={currency} style={{ fontSize: '2.5rem' }}>{isCurrency ? formatCurrency((results[0] && results[0][agg]) || '0', currency) : (results[0] && results[0][agg]) || '0'}</div>
    {labels[agg] && <label>{labels[agg]}</label>}
    {!labels[agg] && <label>{translate(`nclusion.field.${service}`)} {valueField === 'id' ? '' : `${translate(`nclusion.field.${valueField}`)} `}{startCase(agg)}</label>}
  </div>)}</>
}

export default SimpleAggregateComponent
