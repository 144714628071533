import { useEffect } from 'react'
import { useListContext } from "react-admin"

const HiddenFilter = ({ source, value, alwaysOn, }: { source: string, value?: any, alwaysOn?: boolean }) => {
  const { filterValues, setFilters, displayedFilters } = useListContext()

  useEffect(() => {
    if (value) {
      setFilters({ ...filterValues, [source]: value }, displayedFilters)
    }
  }, [source, value])

  return <></>
}

export default HiddenFilter
